
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
